<template lang="pug">
#EditFlag(v-if="flag")
    h4 New flag
    p.mt-4.text-secondary For flags related to features, such as damage or misinstallation, select or create the feature first.
    br
    v-select(v-model="flag.flagType" :items="flagTypeItems"  :menuProps="{ maxHeight: 500 }" dense label="Type of flag")
    template(v-if="flag.flagType")
        v-select(v-model="flag.subtype" v-if="subtypeItems.length" :items="subtypeItems" label="Sub-type")
        v-btn(@click="startDrawing") {{ flag.geometry ? 'Update' : 'Add' }} flag location
        v-text-field(v-if="flag.mapillaryImageKey" v-model="flag.mapillaryImageKey" readonly label="Imagery Id")
        v-textarea.mt-3(v-model="flag.notes" outlined label="Notes")
    v-btn(v-if="canSave" @click="save" color="primary") Save flag
    v-btn(@click="cancel") Cancel
    v-alert(v-if="successMessage" dense text type="success") {{ successMessage }}

</template>

<script>
import { EventBus } from '@/EventBus';
import * as api from '@/api';
import { flagTypes } from '@/featureTypes';
const itemList = (items) => items.map((id) => ({ text: id, value: id }));

export default {
    name: 'EditFlag',
    data: function() {
        return {
            flag: null,
            feature: null,
            successMessage: null,
        };
    },
    created() {
        window.EditFlag = this;
        EventBus.$on('start-flag', (feature) => {
            this.flag = {
                flagType: null,
                subtype: null,
                geometry: null,
            };
            this.flag.mapillaryImageKey = window.Imagery.getSelectedImageKey();
            this.feature = feature;
            if (feature) {
                this.flag.FeatureId = this.feature.id;
            }
        });
        EventBus.$on('edit-flag', (flag) => {
            this.flag = Object.assign({}, flag);
            // update the image with the current image
            this.flag.mapillaryImageKey = window.Imagery.getSelectedImageKey();
        });
        EventBus.$on('flag-geometry', (geometry) => {
            // this.flag.geometry = geometry;
            this.$set(this.flag, 'geometry', geometry);
        });
    },
    methods: {
        async save() {
            const payload = this.flag;
            let result;

            if (this.flag.id) {
                result = await api.putApi('flag', payload);
                this.$nextTick(() => (this.successMessage = 'Updated flag.'));
            } else {
                result = await api.postApi('flag', payload);
                Object.assign(this.flag, result);
                this.$nextTick(() => (this.successMessage = 'Added flag.'));
            }
            EventBus.$emit('flag-saved', this.flag);
        },
        async cancel() {
            this.flag = null;
            EventBus.$emit('cancel-edit-flag');
        },
        startDrawing() {
            EventBus.$emit('draw-flag', {
                geometryType: 'Point',
                type: this.flag.flagType,
                subtype: this.flag.subtype,
            });
        },
    },
    computed: {
        flagTypeItems() {
            return Object.values(flagTypes).filter(
                (def) => !!this.feature == !!def.linkedFeature
            );
        },
        subtypeItems() {
            return itemList(flagTypes[this.flag.flagType].subtypes || []);
        },
        canSave() {
            return (
                this.flag.flagType &&
                (this.flag.geometry || this.flag.featureId)
            );
        },
    },
    watch: {
        flag: {
            deep: true,
            handler() {
                // this.saved = false;
                console.log('changed');
                this.successMessage = null;
            },
        },
    },
};
</script>

<style scoped></style>
