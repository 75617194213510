import axios from 'axios';
export const apiBase =
    window.location.host.match(/localhost/) &&
    !window.location.hash.match(/prod/)
        ? 'http://localhost:7030/api'
        : 'https://map.safe7y.com/api';

async function authHeaders() {
    const auth = window.App.$auth;
    const token = await auth.getTokenSilently();
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            UserId: auth.user.sub,
            UserNickname: auth.user.nickname,
            UserEmail: auth.user.email,
        },
    };
}

// export apiBase;
export async function queryApi(query, options) {
    try {
        const res = await axios.get(`${apiBase}/${query}`, options);
        return res.data;
    } catch (error) {
        console.error({ ...error });
        throw error.response.data;
    }
}

export async function postApi(path, body, options) {
    try {
        const res = await axios.post(`${apiBase}/${path}`, body, {
            ...options,
            ...(await authHeaders()),
        });
        return res.data;
    } catch (error) {
        console.error({ ...error });
        throw error.response.data;
    }
}

export async function putApi(path, body, options) {
    try {
        const res = await axios.put(`${apiBase}/${path}`, body, {
            ...options,
            ...(await authHeaders()),
        });
        return res.data;
    } catch (error) {
        console.error({ ...error });
        throw error.response.data;
    }
}

export async function deleteApi(path, options) {
    try {
        const res = await axios.delete(`${apiBase}/${path}`, {
            ...options,
            ...(await authHeaders()),
        });
        return res.data;
    } catch (error) {
        console.error({ ...error });
        throw error.response.data;
    }
}

export function apiUrl(path) {
    return `${apiBase}/${path}`;
}
