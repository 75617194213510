<template lang="pug">
#LayersPanel
    h3 Layers
    v-expansion-panels(accordion v-model="panels" ref="panels")
        v-expansion-panel
            v-expansion-panel-header Features
            v-expansion-panel-content
                FeatureFilter(:mapZoom="mapZoom")
        v-expansion-panel
            v-expansion-panel-header Flags
            v-expansion-panel-content
                FlagFilter(:mapZoom="mapZoom")
        v-expansion-panel
            v-expansion-panel-header Mapillary
            v-expansion-panel-content
                v-switch.mt-0(v-model="showImagery" label="Imagery")
                v-alert(:value="mapZoom < 14" icon="mdi-alert-circle" type="warning" elevation="0" outlined color="warning") Zoom in to see objects
                v-switch.mt-0(v-model="showObjects" label="Detected objects")
                v-checkbox.ml-4.mt-n4.small(v-if="showObjects" v-model="showHiddenObjects" label="Show discarded objects")
                div(v-if="showObjects")
                    h3 Filter object types
                    ObjectFilter(:mapZoom="mapZoom")
</template>

<script>
import FeatureFilter from './map/FeatureFilter.vue';
import FlagFilter from './map/FlagFilter.vue';
import ObjectFilter from './map/ObjectFilter.vue';
import { EventBus } from '@/EventBus';
export default {
    name: 'LayersPanel',
    components: { FeatureFilter, FlagFilter, ObjectFilter },
    data: () => ({
        showImagery: true,
        showObjects: true,
        showHiddenObjects: false,
        mapZoom: 15,
        panels: [],
    }),
    created() {
        window.LayersPanel = this;
        EventBus.$on('map-zoom', (zoom) => (this.mapZoom = zoom));
        EventBus.$on('start-feature', () => (this.panels = null));
        EventBus.$on('select-feature', () => (this.panels = null));
        EventBus.$on('start-flag', () => (this.panels = null));
        EventBus.$on('select-flag', () => (this.panels = null));
        EventBus.$on('select-object', () => (this.panels = null));
    },
    watch: {
        showImagery() {
            EventBus.$emit('update-showImagery', this.showImagery);
        },
        showObjects() {
            EventBus.$emit('update-showObjects', this.showObjects);
        },
        showHiddenObjects() {
            EventBus.$emit('update-showHiddenObjects', this.showHiddenObjects);
        },
    },
};
</script>

<style scoped></style>
