<template lang="pug">
v-select#Basemap.mapboxgl-ctrl(v-model="basemap" :items="basemaps" dense label="Basemap")
</template>

<script>
import axios from 'axios';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-dev';
import { EventBus } from '@/EventBus';
export default {
    name: 'Basemap',
    props: ['basemaps', 'map'],
    data: function() {
        const stored = window.localStorage.getItem('basemap');

        return {
            basemap:
                this.basemaps.find((b) => b.value === stored) ||
                this.basemaps[0],
        };
    },
    created() {
        window.Basemap = this;
        EventBus.$on('load-basemap', () => {
            switchBasemap(this.map, this.basemap.value);
        });
    },
    watch: {
        basemap(styleId) {
            switchBasemap(this.map, styleId);
        },
    },
};

//https://github.com/mapbox/mapbox-gl-js/issues/4006#issuecomment-368273916
// styleID should be in the form "mapbox/satellite-v9"
async function switchBasemap(map, styleID) {
    if (styleID) {
        window.localStorage.setItem('basemap', styleID);
    }
    const { data: newStyle } = await axios.get(
        `https://api.mapbox.com/styles/v1/${styleID}?access_token=${mapboxgl.accessToken}`
    );
    // our custom generated sprite via update-sprite.sh
    newStyle.sprite =
        window.location.origin + window.location.pathname + 'sprite/sprite';
    let currentStyle = map.getStyle();
    if (!currentStyle) {
        map.setStyle(newStyle);
        return;
    }

    // ensure any sources from the current style are copied across to the new style
    newStyle.sources = Object.assign(
        {},
        currentStyle.sources,
        newStyle.sources
    );

    // find the index of where to insert our layers to retain in the new style
    let labelIndex = newStyle.layers.findIndex((el) => {
        return el.id == 'waterway-label';
    });

    // default to on top
    if (labelIndex === -1) {
        labelIndex = newStyle.layers.length;
    }
    const appLayers = currentStyle.layers.filter((el) => {
        // app layers are the layers to retain, and these are any layers which have a different source set
        return (
            el.source &&
            el.source != 'mapbox://mapbox.satellite' &&
            el.source != 'mapbox' &&
            el.source != 'composite'
        );
    });
    newStyle.layers = [
        ...newStyle.layers.slice(0, labelIndex),
        ...appLayers,
        ...newStyle.layers.slice(labelIndex, -1),
    ];
    map.setStyle(newStyle);
}
</script>

<style scoped></style>
