<template lang="pug">
#ViewFlag(v-show="flag && flag.flagType")
    v-card(outlined).mb-2
        v-system-bar(light color="white")
            v-spacer
            v-btn.mt-4(text @click="$emit('clickClose')")
                v-icon.mr-n8 mdi-close
        v-card-title.subtitle-1 {{ flagTypeLabel }}

            //- v-simple-table
            //-     tbody
            //-         tr(v-if="flag.subtype")
            //-             th Subtype
            //-             td {{ flag.subtype }}

        v-card-text.mb-n4(v-if="flag.notes || flag.subtype")
            p.subtitle-2(v-if="flag.subtype") {{ flag.subtype }}
            p(v-if="mapillaryImage") Based on this image:
                a(v-if="flag.mapillaryImageKey" @click="openImage")
                    img.imagery(v-if="flag.mapillaryImageKey" :src="mapillaryImage" style="width:100%")
            p.subtitle-3.text--primary {{ flag.notes }}
            v-btn(text @click="copyLink")
                v-icon.mr-2.mb-0 mdi-link
                | Copy share link
            //- p.subtitle-3.mt-8.mb-0
            //-     a(:href="linkUrl" @click.prevent="copyLink")
            //-         v-icon.mr-2 mdi-link
            //-         | Copy share link
        v-card-actions
            v-row
                v-col(v-if="canEdit")
                    v-btn(text @click="clickEdit" color="accent") Edit
                    DeleteButton(v-show="flag" @confirm="clickDelete" message="Sure you want to delete this flag?")

                v-col.text-right.body-2.text--secondary {{ flag.updatedByNickname ? flag.updatedByNickname + ', ' : '' }}{{ editedTime }}

</template>

<script>
import humanTime from 'human-time';
import { EventBus } from '@/EventBus';
import DeleteButton from '@/components/DeleteButton.vue';
import * as api from '@/api';
import { copyToClipboard } from '@/clipboard';
import { flagTypes } from '@/featureTypes';
// import axios from 'axios';
// TODO don't allow editing if we didn't create it. (server-side too)
export default {
    name: 'ViewFlag',
    components: { DeleteButton },
    props: ['flag', 'canEdit'],
    data: () => ({}),
    created() {
        window.ViewFlag = this;
    },
    methods: {
        clickEdit() {
            EventBus.$emit('edit-flag', this.flag);
        },
        async clickDelete() {
            try {
                await api.deleteApi(`flag/${this.flag.id}`);
                EventBus.$emit('editing-message', 'Flag deleted', 'success');
                EventBus.$emit('flag-deleted');
            } catch (e) {
                console.error(e);
                EventBus.$emit(
                    'editing-message',
                    'Unable to delete flag',
                    'error'
                );
            }
        },
        copyLink() {
            copyToClipboard(this.linkUrl);
        },
        // This opens the image provided - provided Imagery is turned on
        openImage() {
            window.Imagery.selectImage(this.flag.mapillaryImageKey);
        },
    },
    computed: {
        editedTime() {
            return humanTime(
                (Date.now() - new Date(this.flag.updatedAt)) / 1000
            );
        },
        flagTypeLabel() {
            const flagDef = flagTypes[this.flag.flagType];
            return (flagDef && flagDef.text) || this.flag.flagType;
        },
        linkUrl() {
            const l = window.location;
            return `${l.host}${l.pathname}#flag=${this.flag.id}`;
        },
        mapillaryImage() {
            return (
                this.flag.mapillaryImageKey &&
                `https://images.mapillary.com/${this.flag.mapillaryImageKey}/thumb-640.jpg`
            );
        },
    },
};
</script>

<style scoped>
a {
    text-decoration: none;
}

img.imagery {
    width: 100%;
    border: 1px solid #ccc;
}
</style>
