<template lang="pug">
#Imagery(v-show="visible && viewerVisible")
    #mly
</template>

<script>
import axios from 'axios';
import { queryMapillary, mapillaryTilesUrl } from '@/mapillary';
import { Viewer } from 'mapillary-js';
import 'mapillary-js/dist/mapillary.css';
import { EventBus } from '@/EventBus';
import config from '@/config';

export default {
    name: 'Imagery',
    data: () => ({ viewerVisible: false }),
    props: ['visible'],
    created() {
        window.Imagery = this;
        EventBus.$on('map-loaded', (map) => this.init(map));
    },
    methods: {
        init(map) {
            this.map = map;
            if (map.getSource('imagery')) {
                return;
            }
            this.mly = new Viewer({
                accessToken: config.mapillaryClientToken,
                container: 'mly',
            });
            this.minZoom = 6;
            window.mly = this.mly;
            map.U.addVector('imagery', {
                tiles: [mapillaryTilesUrl('mly1_public')],
                minzoom: 6,
                maxzoom: 14,
            });
            map.U.addCircle('imagery-circle-highlight', 'imagery', {
                sourceLayer: 'image',
                circleColor: 'hsla(120,100%,60%,0.8)',
                circleRadius: 30,
                circleBlur: 1,
                filter: false,
            });
            map.U.addCircle('imagery-circle', 'imagery', {
                sourceLayer: 'image',
                circleColor: 'green',
                circleBlur: 1,
            });
            map.U.hoverPointer('imagery-circle');
            map.U.addLine('imagery-line', 'imagery', {
                sourceLayer: 'sequence',
                lineColor: 'hsla(120,80%,30%,0.3)',
                lineWidth: 4,
                lineBlur: 6,
            });
            map.U.addGeoJSON('viewer');
            map.U.addSymbol('viewer-icon', 'viewer', {
                iconImage: 'regular-0',
                iconRotationAlignment: 'map',
                // iconRotate: ['+', ['get', 'angle'], 180],
                iconRotate: ['get', 'angle'],
                iconAllowOverlap: true,
                // iconSize: 0.4,
                iconSize: 1,
                iconOffset: [0, 5],
                iconAnchor: 'bottom',
            });

            // Select the image if the image circle get clicked
            map.on('click', 'imagery-circle', async (e) => {
                const f = e.features[0];
                this.selectImage(f.properties.id);
                // when a new image is selected we center the map on it
                var coordinates = f.geometry.coordinates.slice();
                map.flyTo({
                    center: coordinates,
                });
            });

            // Display the date of the image on mouse over
            map.on('mouseenter', 'imagery-circle', function(e) {
                // Change the cursor style as a UI indicator.
                map.getCanvas().style.cursor = 'pointer';
                var coordinates = e.features[0].geometry.coordinates.slice();

                // TODO deal with timezones?
                var captureDate = new Date(e.features[0].properties.captured_at)
                    .toString()
                    .slice(4, 15);
                var description = 'Captured: ' + captureDate;
                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] +=
                        e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                // Populate the popup and set its coordinates
                // based on the feature found.
                map.popup
                    .setLngLat(coordinates)
                    .setHTML(description)
                    .addTo(map);
            });
            map.on('mouseleave', 'imagery-circle', function() {
                map.getCanvas().style.cursor = '';
                map.popup.remove();
            });

            EventBus.$on('image-show', (id) => this.selectImage(id));

            this.mly.on('image', ({ image }) => {
                this.selectImage(image.id);
                // when the viewer moves images we also center the map on it
                // note this is the computed location
                map.flyTo({
                    center: image.lngLat,
                });
            });
            this.updateVisibility();

            this.mly.on('moveend', async () => {
                const angle = await this.mly.getBearing();
                const position = await this.mly.getPosition();
                map.U.setData('viewer', {
                    type: 'Feature',
                    properties: {
                        angle,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [position.lng, position.lat],
                    },
                });
            });
            window.onresize = () => this.mly.resize();
        },
        updateVisibility() {
            this.map.U.toggle(
                [
                    'imagery-circle-highlight',
                    'imagery-circle',
                    'imagery-line',
                    'viewer-icon',
                ],
                this.visible
            );
        },
        async selectImage(imageKey) {
            if (imageKey !== this.selectedImageKey) {
                this.selectedImageKey = imageKey;
                this.map.U.setFilter(
                    ['imagery-circle-highlight'],
                    ['==', ['get', 'id'], imageKey]
                );
                await this.mly.moveTo(imageKey);
                this.viewerVisible = true;
                this.$nextTick(() => this.mly.resize());
            }
        },
        getSelectedImageKey() {
            return this.viewerVisible ? this.selectedImageKey : null;
        },
    },
    watch: {
        visible() {
            this.updateVisibility();
        },
    },
};
</script>

<style scoped>
#Imagery {
    position: absolute;
    /* width: 200px; */
    height: 35%;
    margin-right: 10px;
    width: 100%;
    bottom: 20px; /* Don't cover attribution */
    background: white;
}
#mly {
    width: 100%;
    height: 100%;
}
</style>
