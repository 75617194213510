<template lang="pug">
#app
  v-app
    // This displays the feature panel
    v-navigation-drawer(v-model="drawer" stateless app clipped hide-overlay :permanent="drawer" :width="smallScreen ? '100vh' : '400px'")
        .text-right.mb-8
            v-btn.text-center.mt-3.mr-3.only-mobile(text @click="drawer=false")
                v-icon mdi-map
                | Go to map
        FeaturePanel
    // This shows a toolbar with the logo and login informaiton
    v-app-bar(app color="white" clipped-left)
        a(href='https://www.safe7y.com/')
            img(:src='require("./assets/200402-logo_vector_plain.png")' height="40" contain)
        v-spacer
        div(v-if="!$auth.loading").text-center
            v-btn(v-if="!$auth.isAuthenticated" @click="login" color="primary" outlined) Log in
            v-btn(v-if="$auth.isAuthenticated" @click="logout" color="primary" outlined) Log out
    v-main
        div(style="height: 100%; position:relative;")
            Map(v-show="!(smallScreen && drawer)")

</template>

<script>
import FeaturePanel from '@/components/FeaturePanel.vue';
import * as api from '@/api';
import { EventBus } from '@/EventBus';
import Map from '@/components/Map.vue';
export default {
    name: 'App',

    components: {
        Map,
        FeaturePanel,
    },

    data: () => ({
        drawer: true,
        sheet: true,
        smallScreen: window.innerWidth <= 400,
    }),
    computed: {
        pageName() {
            return this.$router.history.current.path;
        },
    },
    watch: {
        $route(to, from) {
            console.log('Routing', to, from);
            this.drawer = to.path === '/';
        },
        drawer() {
            EventBus.$emit('toggle-sidepanel', this.drawer);
        },
    },
    created() {
        window.App = this;
        window.App.EventBus = EventBus;
        window.addEventListener(
            'resize',
            () => (this.smallScreen = window.innerWidth <= 400)
        );

        EventBus.$on('show-sidepanel', () => (this.drawer = true));
        EventBus.$on('select-feature', () => (this.drawer = true));
        EventBus.$on('select-object', () => (this.drawer = true));
        EventBus.$on('select-flag', () => (this.drawer = true));
    },
    methods: {
        login() {
            this.$auth.loginWithRedirect();
        },
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin,
            });
        },
    },
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.login {
    width: 5em;
}

@media screen and (min-width: 768px) {
    .only-mobile {
        display: none;
    }
}
@media screen and (max-width: 767px) {
    .not-mobile {
        display: none;
    }
    .container {
        padding-top: 0;
    }
}
</style>
