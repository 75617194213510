import axios from 'axios';
import config from '@/config';
export async function queryMapillary(path, options = {}) {
    options.params = {
        ...options.params,
        access_token: config.mapillaryClientToken,
    };
    return (await axios.get(`https://graph.mapillary.com/${path}`, options))
        .data;
}

export async function getImageUrls(id) {
    return queryMapillary(id, {
        params: { fields: 'thumb_1024_url,thumb_2048_url' },
    });
}

export function mapillaryTilesUrl(path) {
    return `https://tiles.mapillary.com/maps/vtp/${path}/2/{z}/{x}/{y}?access_token=${config.mapillaryClientToken}`;
}
