import * as changeCase from 'change-case';
const split = (x) => x.split(',');
const Point = { geometryType: 'Point' };
const LineString = { geometryType: 'LineString' };
const featureTypes = {
    Sign: {
        ...Point,
        subtypes: split(
            'Regulatory,Warning,Guide,GeneralInformation,Temporary,School,Rail,Bicycle,Miscellaneous,MileMarker,Delineator'
        ),
    },
    Crash: {
        // but maybe read-only
        ...Point,
        subtypes: split('Fatal,InjuryOnly,PropertyDamageOnly'),
    },
    Signal: {
        ...Point,
        subtypes: split('TrafficSignal,PedestrianSignal,Other'),
    },
    Camera: { ...Point },
    AccessPoint: { ...Point },
    RoadSurfaceMarking: {
        ...Point,
        subtypes: split(
            'ArrowLeft,ArrowRight,ArrowSplitLeftOrStraight,ArrowSplitRightOrStraight,ArrowStraight,CrosswalkZebra,GiveWayRow,GiveWaySingle,StopLine,SymbolBicycle,Text,SingleDotted,DoubleSolid,HalfDotted,Other'
        ),
    },
    RoadSurfaceFeaturePoint: {
        ...Point,
        subtypes: ['SpeedBump'],
    },
    RoadSurfaceFeatureLine: {
        ...LineString,
        label: 'RoadSurfaceFeature',
        subtypes: ['RumbleStrip', 'PavedShoulder', 'GravelShoulder', 'Other'],
    },

    Terminal: {
        ...Point,
        subtypes: split('EndTerminal,CrashCushion,BluntEnd,Other'),
    },
    VehicleBarrier: {
        ...LineString,
        subtypes: 'WBeam,ThriBeam,BoxBeam,Cable,Concrete,Transition,BridgeRail,Other'.split(
            ','
        ),
    },
    Fence: {
        ...LineString,
        subtypes: split('SoundBarrier,PedestrianFence,GameFence,Other'),
    },
    OtherPoint: {
        label: 'Other',
        ...Point, // TODO support point and line others.
    },
    OtherLine: {
        label: 'Other',
        ...LineString,
    },
};

const flagTypes = {
    Damage: {
        subtypes: split('MinorFunctional,MajorDysfunctional'),
        linkedFeature: true,
    },
    MisInstallation: {
        subtypes: split('Assembly,Placement'),
        linkedFeature: true,
    },
    // These ones are all independent of a feature
    UnprotectedHazard: {
        subtypes: split('FixedObjectInClearZone,SteepSlopeOrDropOff'),
    },
    RoadDamage: {
        subtypes: split(
            'PotholeMinor,PotholeSevere,UnmarkedRoad,Flooding,EdgeDrop'
        ),
    },
    AnimalCarcass: {},
    GarbageRefuseLitter: {},
    NaturalDebris: {},
    VehicleDebris: {},
    SpillPollutant: {},
    Other: {},
    OtherIssue: { linkedFeature: true },
};

for (const id of Object.keys(featureTypes)) {
    const def = featureTypes[id];
    def.value = id;
    def.text = changeCase.sentenceCase(def.label || id);
}
for (const id of Object.keys(flagTypes)) {
    const def = flagTypes[id];
    def.value = id;

    def.text = changeCase.sentenceCase(def.label || id);
}
export { featureTypes, flagTypes };
