import { EventBus } from '@/EventBus';
import * as api from '@/api';

class Flags {
    static init(map, Draw) {
        let removeHoverHandler;
        function addHoverHandler() {
            removeHoverHandler = map.U.hoverPointer([
                'flags-circle',
                'flags-icon',
            ]);
        }
        Flags.map = map;
        Flags.Draw = Draw;
        Flags.addLayers(map);
        Flags.userFilter = true;
        addHoverHandler();
        EventBus.$on(
            'drawing-end',
            // exited drawing
            addHoverHandler
        );
        EventBus.$on(
            'drawing-start',
            // entered drawing
            removeHoverHandler
        );

        EventBus.$on('flag-saved', (flag) => {
            Flags.refreshFlagTiles();
            Flags.selectFlag(flag.id);
            Draw.deleteAll();
        });
        EventBus.$on('flag-deleted', () => {
            Flags.refreshFlagTiles();
        });
        EventBus.$on('select-feature', () => {
            Flags.selectFlag();
        });
        EventBus.$on('select-flag', (flag) => {
            Flags.selectFlag(flag.id);
        });
        EventBus.$on('unselect-flag', () => {
            Flags.selectFlag();
        });
        EventBus.$on('zoom-to-flag', (flag) => {
            map.jumpTo({
                center: flag.geometry.coordinates,
                zoom: 15,
            });
            Flags.selectFlag(flag.id);
        });
        EventBus.$on('update-flag-filter', (activeTypes) => {
            Flags.userFilter = [
                'any',
                ...activeTypes.map((type) => ['==', ['get', 'flagType'], type]),
            ];
            Flags.updateFilter();
        });
    }
    static addLayers(map) {
        map.U.addVector('flags', {
            tiles: [api.apiUrl('tile/flag/{z}/{x}/{y}.pbf')],
            minzoom: 10,
        });
        map.U.addCircle('flags-circle-highlight', 'flags', {
            circleColor: 'hsla(10,100%,70%,1)',
            circleRadius: 18,
            circleBlur: 0.25,
            filter: false,
            sourceLayer: 'default',
        });
        map.U.addCircle('flags-circle', 'flags', {
            circleColor: 'hsl(10,95%,50%)',
            circleRadius: [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                4,
                15,
                5,
                20,
                7,
            ],
            circleStrokeColor: 'white',
            circleStrokeWidth: 1,
            filter: ['==', ['geometry-type'], 'Point'],
            sourceLayer: 'default',
            // circleOpacity: 0,
            visibility: 'none',
        });
        map.U.loadImage('red-flag', 'redflag.png');
        map.U.addSymbol('flags-icon', 'flags', {
            iconImage: 'red-flag',
            sourceLayer: 'default',
        });
    }
    static refreshFlagTiles() {
        Flags.map.style.sourceCaches.flags.clearTiles();
        Flags.map.style.sourceCaches.flags.update(Flags.map.transform);
    }
    static selectFlag(id) {
        const filter = id ? ['==', ['get', 'id'], id] : false;
        Flags.map.U.setFilter('flags-circle-highlight', filter);
    }
    static updateFilter() {
        Flags.map.U.setFilter('flags-icon', ['all', Flags.userFilter]);
    }
}
export default Flags;
