<template lang="pug">
#FeaturePanel
    v-container
        // What you see without a feature or a flag open
        div(v-show="!open")
            // What you see if you're not logged in
            .text-center(v-show="!canEdit")
                br
                h3.text-secondary Welcome to map.safe7y, a public map of road features and road hazards.
                br
                p.mt-4.text-secondary 🔍 - Zoom in to see features and flags.
                p.mt-4.text-secondary 🔓 - Login to add features or flags.
                p.mt-4.text-secondary 📖 - Visit&nbsp;
                    a(href='https://wiki.safe7y.com/') the wiki
                    |  for more information

                v-btn(v-if="!$auth.isAuthenticated" @click="$auth.loginWithRedirect()" color="primary" outlined) Log in

            // What you see when logged in
            .text-center(v-show="canEdit")
                br
                h3.text-secondary Thank you for contributing to map.safe7y.
                v-btn.mt-5.mr-2(color="primary" @click="clickCreateFeature") Create feature
                    span(v-if="!isTouchDevice") &nbsp; (f)
                v-btn.mt-5(color="primary" @click="clickCreateFlag") Create flag
                    span(v-if="!isTouchDevice") &nbsp; (x)
                br
                br
                p.mt-4.text-secondary 🔍 - Zoom in to see features and flags.
                p.mt-4.text-secondary 📖 - Visit&nbsp;
                    a(href='https://wiki.safe7y.com/') the wiki
                    |  for more information
        div(v-show="open")
            ViewFeature(v-if="feature && !editingFeature" :feature="feature" @clickEdit="edit" @clickDelete="clickDelete" @clickClose="close" :canEdit="canEdit")
            EditFeature(v-show="editingFeature")
            #flags(v-show="feature && !editingFeature && !editingFlag")
                h3.mt-6(v-if="feature && feature.Flags && feature.Flags.length || canEdit") Flags
                ViewFlag(v-for="flag in feature && feature.Flags" :flag="flag" :canEdit="canEdit" @clickClose="closeFlag")
                v-btn(v-if="!editingFlag && canEdit" @click="addFlag" text color="primary") Add flag
            EditFlag(v-show="editingFlag")
            ViewFlag(v-if="!editingFlag && flag" :flag="flag" :canEdit="canEdit" @clickClose="closeFlag")
            ViewObject(v-if="object" :object="object" :canEdit="canEdit" @clickClose="closeObject" @clickConvert="convertObject")
        .mt-12
            LayersPanel

        v-snackbar(v-model="showMessage" timeout="3000" :color="messageColor") {{ message }}
</template>

<script>
import { EventBus } from '@/EventBus';
import ViewFeature from '@/components/ViewFeature.vue';
import EditFeature from './EditFeature';
import ViewFlag from '@/components/ViewFlag.vue';
import EditFlag from '@/components/EditFlag.vue';
import ViewObject from '@/components/ViewObject.vue';
import LayersPanel from '@/components/LayersPanel.vue';
import * as api from '@/api';
import * as mapillary from '@/mapillary';
import { getHashParam } from '@/hashParams';
export default {
    name: 'FeaturePanel',
    components: {
        ViewFeature,
        EditFeature,
        ViewFlag,
        EditFlag,
        ViewObject,
        LayersPanel,
    },
    data: () => ({
        feature: null,
        flag: null, // stand-alone flag
        object: null, // viewing a mapillary object
        editingFeature: false,
        editingFlag: false,
        message: null,
        showMessage: false,
        messageColor: 'success',
    }),
    created() {
        window.FeaturePanel = this;
        // EventBus.$on('select-feature', () => {
        //     this.open = true;
        // });
        EventBus.$on('select-feature', async (mapFeature) => {
            this.clear();
            const id = mapFeature.id;
            this.feature = { id };
            await this.loadFeature(id);
            // Object.assign(this.$data, { ...initFields, ...feature }); // TODO don't be lazy
        });

        EventBus.$on('unselect-feature', async () => {
            if (!this.editingFeature && !this.editingFlag) {
                this.feature = null;
            }
        });
        EventBus.$on('select-flag', async (mapFlag) => {
            this.feature = null;
            const id = mapFlag.id;
            this.flag = { id };
            await this.loadFlag(id);
        });
        EventBus.$on('unselect-flag', async () => {
            if (!this.editingFlag && !this.editingFlag) {
                this.flag = null;
            }
        });
        EventBus.$on('select-object', async (object) => {
            this.clear();
            this.object = {
                isDiscard: object.properties.isDiscard,
                id: object.properties.id,
                object_value: object.properties.value, // map from tile properties to graph API properties
                last_seen_at: object.properties.last_seen_at,
                first_seen_at: object.properties.first_seen_at,
            };
            this.object = {
                ...this.object, // keep isDiscard
                ...(await this.loadObject(object.properties.id)),
            };
            this.object.id = String(this.object.id);
        });
        EventBus.$on('unselect-object', () => {
            this.object = null;
        });

        EventBus.$on('load-feature', (feature) => {
            this.feature = Object.assign(
                {},
                JSON.parse(JSON.stringify(feature))
            );
            this.editingFlag = false;
            this.editingFeature = false;
        });
        EventBus.$on('start-feature', () => {
            this.clear();
            this.editingFeature = true;
        });
        EventBus.$on('load-flag', (flag) => {
            this.flag = Object.assign({}, JSON.parse(JSON.stringify(flag)));
            this.editingFlag = false;
            this.editingFeature = false;
        });
        EventBus.$on(['cancel-edit-feature', 'cancel-edit-flag'], () => {
            this.editingFlag = false;
            this.editingFeature = false;
        });
        EventBus.$on('editing-message', (message, color) => {
            this.showMessage = true;
            this.message = message;
            this.messageColor = color;
        });
        EventBus.$on('edit-flag', (flag) => {
            this.editingFlag = true;
        });
        EventBus.$on('flag-saved', async (flag) => {
            this.editingFlag = false;
            if (flag.featureId) {
                await this.loadFeature(flag.featureId);
            } else {
                await this.loadFlag(flag.id);
            }
        });
        EventBus.$on('flag-deleted', async (flag) => {
            this.editingFlag = false;
            await this.loadFeature(this.feature.id);
        });
        EventBus.$on('map-loaded', () => this.loadFromUrl());
    },
    mounted() {
        document.onkeydown = (e) => {
            if (e.target.tagName === 'INPUT') {
                return;
            }
            if (e.key === 'Escape') {
                EventBus.$emit('unselect-feature');
                EventBus.$emit('unselect-flag');
                EventBus.$emit('unselect-object');
            } else if (e.key === 'f' && !this.open) {
                EventBus.$emit('start-feature');
            } else if (e.key === 'x' && !this.open) {
                EventBus.$emit('start-flag');
            }
        };
    },
    computed: {
        open() {
            return (
                this.feature ||
                this.editingFeature ||
                this.editingFlag ||
                this.flag ||
                this.object
            );
        },
        canEdit() {
            return this.$auth.isAuthenticated;
        },
        isTouchDevice() {
            return (
                'ontouchstart' in window ||
                navigator.maxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0
            );
        },
    },
    methods: {
        clear() {
            this.feature = null;
            this.flag = null;
            this.object = null;
            this.editingFeature = false;
            this.editingFlag = false;
        },
        edit() {
            this.editingFeature = true;
            EventBus.$emit('edit-feature', this.feature);
        },
        async clickDelete() {
            try {
                const result = await api.deleteApi(
                    `feature/${this.feature.id}`
                );
                this.showMessage = true;
                this.message = 'Feature deleted.';
                this.messageColor = 'success';
                this.close();
                EventBus.$emit('feature-deleted');
            } catch (e) {
                this.showMessage = true;
                this.message = 'Unable to delete feature.';
                this.messageColor = 'error';
            }
        },
        clickCreateFeature() {
            EventBus.$emit('start-feature');
        },
        clickCreateFlag() {
            EventBus.$emit('start-flag');
            this.editingFlag = true;
        },
        addFlag() {
            EventBus.$emit('start-flag', this.feature);
            this.editingFlag = true;
            this.editingFeature = false;
        },
        close() {
            EventBus.$emit('unselect-feature');
            this.feature = null;
            this.editingFeature = false;
        },
        closeFlag() {
            EventBus.$emit('unselect-flag');
            this.flag = null;
            this.editingFlag = false;
        },
        closeObject() {
            EventBus.$emit('unselect-object');
        },
        async loadFeature(id) {
            const feature = await api.queryApi(`feature/${id}`);
            EventBus.$emit('load-feature', feature);
            return feature;
        },
        async loadFlag(id) {
            const flag = await api.queryApi(`flag/${id}`);
            EventBus.$emit('load-flag', flag);
            return flag;
        },
        async loadObject(id) {
            const object = await mapillary.queryMapillary(id, {
                params: {
                    fields:
                        'geometry,images,object_value,object_type,last_seen_at,first_seen_at',
                },
            });
            if (object.geometry) {
                if (object.geometry.coordinates[0] > 0) {
                    // API is incorrect atm. Presumably they will fix at some point, hence the test.
                    object.geometry.coordinates.reverse();
                }
            }
            EventBus.$emit('load-object', object);
            return object;
        },
        convertObject(object) {
            // TODO pre-populate
            console.log(object);
            EventBus.$emit('start-feature', { mapillaryFeature: object });
        },
        async loadFromUrl() {
            const flagId = getHashParam('flag');
            if (flagId) {
                const flag = await this.loadFlag(flagId);
                EventBus.$emit('zoom-to-flag', flag);
                return;
            }
            const featureId = getHashParam('feature');
            if (featureId) {
                const feature = await this.loadFeature(featureId);
                EventBus.$emit('zoom-to-feature', feature);
            }
            // const flagId = getHashParam('flag');
        },
    },
};
</script>

<style scoped></style>
