export function getHashParam(param, defaultValue, json) {
    const url = new URL(window.location.toString().replace('/#', '/?'));
    const getValue = url.searchParams.get(param);
    if (getValue) {
        return json ? JSON.parse(getValue) : getValue;
    } else {
        return defaultValue;
    }
}

export function setHashParam(param, value, json) {
    if (value === undefined || value === null) {
        value = '';
    }
    const url = new URL(window.location.toString().replace('/#', '/?'));
    const setValue = json ? JSON.stringify(value) : value;
    url.searchParams.set(param, setValue);
    window.location.hash = url.searchParams.toString();
}

export function setHashParams(params) {
    for (const k of Object.keys(params)) {
        setHashParam(k, params[k]);
    }
}

export function urlWithHashParam(param, value) {
    const url = new URL(window.location.toString().replace('/#', '/?'));
    url.searchParams.set(param, value);
    return url.href.replace(/\/\?/, '/#');
}
