<template lang="pug">

v-dialog#DeleteButton(v-model="open" width="300" )
    template(v-slot:activator="{ on, attrs }")
        v-btn( color="red" v-bind="attrs" v-on="on" text) {{ label }}
    v-card
        v-card-title Confirm deletion
        v-card-text {{ message }}
        v-card-actions
            v-btn(text color="red" @click="clickDelete") {{ label }}
            v-btn(text @click="clickCancel") Cancel
</template>

<script>
export default {
    name: 'DeleteButton',
    props: {
        message: { type: String },
        label: {
            type: String,
            default: 'Delete',
        },
    },
    data: () => ({ open: false }),
    created() {
        window.DeleteButton = this;
    },
    methods: {
        clickDelete() {
            this.open = false;
            this.$emit('confirm');
        },
        clickCancel() {
            this.open = false;
            this.$emit('cancel');
        },
    },
};
</script>

<style scoped></style>
