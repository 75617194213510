import * as d3 from 'd3-fetch';
let mappingTablePromise = d3.csv('mapillary_mapping_table.csv');

export async function convertMapillaryFeature(feature, mapillaryFeature) {
    feature.geometry = mapillaryFeature.geometry;
    feature.source = `Mapillary Map Feature of type ${mapillaryFeature.object_value}`;
    feature.mapillaryObjectKey = String(mapillaryFeature.id);
    const mappingTable = await mappingTablePromise;
    const row = mappingTable.find(
        (r) => r.MapillaryType === mapillaryFeature.object_value
    );
    if (row) {
        feature.featureType = row.FeatureType;
        if (row.Subtype) {
            feature.subtype = row.Subtype;
        }
        if (row.Device) {
            feature.device = row.Device;
        }
    }
    return feature;
}
