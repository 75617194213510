<template lang="pug">
#Feature(v-if="feature")
    v-card
        v-system-bar(light color="white")
            v-spacer
            v-btn.mt-4(text @click="$emit('clickClose')")
                v-icon.mr-n8 mdi-close
        v-card-title
            .text--primary {{ featureTypeLabel }}

        v-card-text.mb-n4
            v-simple-table
                tbody
                    //- tr
                    //-     th Type
                    //-     td {{ feature.featureType }}
                    tr(v-if="feature.subtype")
                        th Subtype
                        td {{ feature.subtype }}
                    tr(v-if="feature.device")
                        th Device
                        td {{ feature.device }}
                    tr(v-if="feature.roadType")
                        th Road type
                        td {{ feature.roadType }}
                    tr(v-if="feature.direction")
                        th Direction
                        td {{ feature.direction }}
                    tr(v-if="feature.roadNumber")
                        th Road number
                        td {{ feature.roadNumber }}
                    tr(v-if="feature.streetName")
                        th Street name
                        td {{ feature.streetName }}
                    tr(v-if="feature.crashDate")
                        th Crash date
                        td {{ feature.crashDate.slice(0,10) }}
                    tr(v-if="feature.totalFatalities")
                        th Fatalities
                        td {{ feature.totalFatalities }}
                    tr(v-if="feature.source")
                        th Source
                        td {{ feature.source }}
                    //- tr(v-if="feature.wikiUrl")
                    //-     th Wiki URL
                    //-     td
                    //-         a(:href="feature.wikiUrl") {{ feature.wikiUrl }}
                    tr(v-if="wikiUrl")
                        th Wiki URL
                        td
                            a(:href="wikiUrl" target="wikipage") {{ wikiUrl }}
                    tr(v-if="feature.featureName")
                        th Feature name
                        td {{ feature.featureName }}
                    tr(v-if="feature.featureDescription")
                        th Description
                        td {{ feature.featureDescription }}
                    tr(v-if="feature.length")
                        th Length
                        td {{ round(feature.length) }} m ({{ round(feature.length * 3.28084) }} ft.
                    //- tr
                    //-     th Share link
                    //-     td
                    //-         a(:href="linkUrl" @click.prevent="copyLink")
                    //-             img(src="link.png" style="width:1.5em")
                    //-             | Copy URL
            p(v-if="feature.notes") {{ feature.notes }}
            //- TODO bug - mapillaryImage not defined
            p(v-if="mapillaryImage") Based on this image:
                a(v-if="feature.mapillaryImageKey" @click="clickImage")
                    img.imagery(v-if="feature.mapillaryImageKey" :src="mapillaryImage.thumb_1024_url" style="width:100%")
            v-btn.ml-n4(text @click="copyLink")
                v-icon.mr-2.mb-0 mdi-link
                | Copy share link

        v-card-actions(v-if="canEdit")
            v-btn(color="primary" @click="$emit('clickEdit')" text) Edit
            DeleteButton(@confirm="$emit('clickDelete')" message="Sure you want to delete this feature?")
    v-col.text-right.body-2.text--secondary {{ feature.updatedByNickname ? feature.updatedByNickname + ', ' : '' }}{{ editedTime }}


</template>

<script>
import DeleteButton from '@/components/DeleteButton.vue';
import humanTime from 'human-time';
import { featureTypes } from '@/featureTypes';
import { copyToClipboard } from '@/clipboard';
import { getImageUrls } from '@/mapillary';
import { EventBus } from '@/EventBus';
export default {
    name: 'ViewFeature',
    components: { DeleteButton },
    props: ['feature', 'canEdit'],
    data: () => ({ mapillaryImage: null }),
    created() {
        window.ViewFeature = this;
    },
    computed: {
        editedTime() {
            return humanTime(
                (Date.now() - new Date(this.feature.updatedAt)) / 1000
            );
        },
        featureTypeLabel() {
            const featureDef = featureTypes[this.feature.featureType];
            return (featureDef && featureDef.text) || this.feature.featureType;
        },
        wikiUrl() {
            return (
                this.feature &&
                this.feature.subtype &&
                `https://wiki.safe7y.com/en/Features/${this.feature.featureType}/${this.feature.subtype}`
            );
        },
        linkUrl() {
            const l = window.location;
            return `${l.host}${l.pathname}#feature=${this.feature.id}`;
        },
    },
    methods: {
        round(m) {
            return (Math.round(m * 10) / 10).toLocaleString();
        },
        copyLink() {
            copyToClipboard(this.linkUrl);
        },
        clickImage() {
            EventBus.$emit('image-show', this.feature.mapillaryImageKey);
        },
    },
    watch: {
        async feature() {
            if (this.feature.mapillaryImageKey) {
                const urls = await getImageUrls(this.feature.mapillaryImageKey);
                this.mapillaryImage = urls;
            } else {
                this.mapillaryImage = null;
            }
        },
    },
};
</script>

<style scoped></style>
