export default function authConfig() {
    if (window.location.hostname === 'localhost') {
        return {
            clientId: '64JqAGbBt0El9i02bp3dZ2yo2qhMRPph',
            domain: 'mapswell.au.auth0.com',
            audience: 'https://gitlab.com/54f37y/safety_map/',
        };
    } else {
        return {
            clientId: '9cfJIoQQplbo87LtL87a7qGQU4Sphyq0',
            domain: 'safe7y.us.auth0.com',
            audience: 'https://map.safe7y.com',
        };
    }
}
