import { EventBus } from '@/EventBus';
import * as api from '@/api';
import axios from 'axios';
class Features {
    static init(map, Draw) {
        let removeHoverHandler;
        function addHoverHandler() {
            removeHoverHandler = map.U.hoverPointer([
                'features-circle',
                'features-line',
                'features-line-under',
                'features-icon',
            ]);
        }

        Features.map = map;
        Features.Draw = Draw;
        Features.userFilter = true;
        map.U.addVector('features', {
            tiles: [api.apiUrl('tile/feature/{z}/{x}/{y}.pbf')],
            minzoom: 10,
        });
        map.U.addLine('features-line-highlight', 'features', {
            lineColor: 'hsla(50,100%,50%,1)',
            lineWidth: 12,
            lineCap: 'round',
            sourceLayer: 'default',
            filter: false,
        });
        const lineWidths = [12, 1, 15, 3, 20, 6];
        map.U.addLine('features-line-under', 'features', {
            lineColor: 'white',
            lineWidth: [
                'interpolate',
                ['linear'],
                ['zoom'],
                ...lineWidths.map((width, i) => width + (i % 2 ? 2 : 0)),
            ],
            lineCap: 'round',
            lineJoin: 'round',
            sourceLayer: 'default',
        });
        map.U.addLine('features-line', 'features', {
            lineColor: 'hsl(200,80%,50%)',
            lineWidth: ['interpolate', ['linear'], ['zoom'], ...lineWidths],
            lineCap: 'round',
            lineJoin: 'round',
            sourceLayer: 'default',
        });
        map.U.addCircle('features-circle-highlight', 'features', {
            circleColor: 'hsla(200,100%,70%,1)',
            circleRadius: 18,
            circleBlur: 0.25,
            filter: false,
            sourceLayer: 'default',
        });

        map.U.addCircle('features-circle', 'features', {
            circleColor: [
                'match',
                ['get', 'featureType'],
                'Crash',
                'hsl(200,80%,80%)',
                'hsl(200,80%,50%)',
            ],
            circleRadius: [
                'interpolate',
                ['linear'],
                ['zoom'],
                12,
                ['match', ['get', 'featureType'], 'Crash', 3, 5],
                15,
                7,
                20,
                10,
            ],
            circleStrokeColor: 'white',
            circleStrokeWidth: ['match', ['get', 'featureType'], 'Crash', 1, 2],
            sourceLayer: 'default',
            // filter set later
        });
        const iconImageExpr = [
            'concat',
            ['get', 'featureType'],
            '-',
            ['get', 'subtype'],
        ];
        map.U.addSymbol('features-icon', 'features', {
            sourceLayer: 'default',
            iconImage: iconImageExpr,
        });
        Features.hideRedundantCircles(map, iconImageExpr); // async

        addHoverHandler();
        EventBus.$on(
            'drawing-end',
            // exited drawing
            () => {
                addHoverHandler();
            }
        );
        EventBus.$on(
            'drawing-start',
            // entered drawing
            () => {
                removeHoverHandler();
            }
        );

        EventBus.$on('select-feature', (feature) => {
            Features.selectFeature(feature.id);
        });

        EventBus.$on('unselect-feature', () => {
            Features.unselectFeature();
        });

        EventBus.$on('feature-saved', (feature) => {
            Features.refreshFeatureTiles();
            Features.selectFeature(feature.id);
            Features.Draw.deleteAll();
        });
        EventBus.$on('feature-deleted', () => {
            Features.refreshFeatureTiles();
        });
        EventBus.$on('select-flag', () => {
            Features.unselectFeature();
        });
        EventBus.$on('zoom-to-feature', (feature) => {
            map.jumpTo({
                center: feature.geometry.coordinates,
                zoom: 15,
            });
            Features.selectFeature(feature.id);
        });
        EventBus.$on('update-feature-filter', (activeFeatureTypes) => {
            Features.userFilter = [
                'any',
                ...activeFeatureTypes.map((type) => [
                    '==',
                    ['get', 'featureType'],
                    type,
                ]),
            ];
            Features.updateFilter();
        });
    }
    static selectFeature(id) {
        Features.map.U.setFilter('features-line-highlight', [
            '==',
            ['get', 'id'],
            id,
        ]);
        Features.map.U.setFilter('features-circle-highlight', [
            'all',
            ['==', ['geometry-type'], 'Point'],
            ['==', ['get', 'id'], id],
        ]);
    }
    static unselectFeature() {
        Features.map.U.setFilter(
            ['features-line-highlight', 'features-circle-highlight'],
            false
        );
    }
    static refreshFeatureTiles() {
        Features.map.style.sourceCaches.features.clearTiles();
        Features.map.style.sourceCaches.features.update(Features.map.transform);
    }
    static updateFilter() {
        Features.map.U.setFilter('features-circle', [
            'all',
            ['==', ['geometry-type'], 'Point'],
            ['!', Features.hasIconFilter],
            Features.userFilter,
        ]);
        Features.map.U.setFilter('features-icon', [
            'all',
            ['==', ['geometry-type'], 'Point'],
            Features.hasIconFilter,
            Features.userFilter,
        ]);
        Features.map.U.setFilter(
            ['features-line-under', 'features-line'],
            Features.userFilter
        );
    }
    static async hideRedundantCircles(map, iconImageExpr) {
        const sprite = (await axios.get(map.getStyle().sprite + '.json')).data;
        // get the list of featureTypes that have an icon in our sprite
        const featureIcons = Object.keys(sprite).filter((id) =>
            /^[A-Z].*-[A-Z].*$/.test(id)
        );
        console.log(featureIcons);
        Features.hasIconFilter = [
            'match',
            iconImageExpr,
            featureIcons,
            true,
            false,
        ]; // ideally we would use ['image'] to work out if the icon exists, but apparently doesn't work
        Features.updateFilter();
    }
}

export default Features;
