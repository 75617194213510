<template lang="pug">
#Object(v-if="object")
    v-card
        v-system-bar(light color="white")
            v-spacer
            v-btn(text @click="$emit('clickClose')")
                v-icon mdi-close
        v-card-title
            .text--primary {{ object.object_value }}

        v-card-text
            p This is an object automatically detected by Mapillary.

            //- Increase aspect-ratio and reduce max-height in both places to crop less of the sides
            v-carousel(v-if="images.length" v-model="selectedImage" width="345" height="250")
                v-carousel-item(v-for="(image, i) in images")
                    a(@click="clickImage(image.id)")
                        v-img(:src="image.thumb_1024_url"  max-width="345" max-height="250" aspect-ratio="0.75")
        v-card-actions(v-if="canEdit")
            v-btn(color="primary" @click="$emit('clickConvert', object)" text) Convert to feature
            DeleteButton(v-if="!object.isDiscard" @confirm="clickDiscard" label="Discard" message="Sure you want to discard this object?")
            DeleteButton(v-if="object.isDiscard" @confirm="clickUndiscard" label="Un-discard" message="Sure you want to un-discard this object?")
</template>

<script>
// TODO handle discard
import DeleteButton from '@/components/DeleteButton.vue';
import { getImageUrls, queryMapillary } from '@/mapillary';
import { EventBus } from '@/EventBus';
import * as api from '@/api';
export default {
    name: 'ViewObject',
    components: { DeleteButton },
    props: ['object', 'canEdit'],
    data: () => ({ selectedImage: null, images: [] }),
    created() {
        window.ViewObject = this;
    },
    computed: {
        p() {
            return (this.object && this.object.properties) || {};
        },
    },
    methods: {
        async clickDiscard() {
            try {
                await api.putApi(`object`, {
                    mapillaryObjectKey: this.object.id,
                    status: 'discard',
                    geometry: this.object.geometry,
                });
                EventBus.$emit('object-discarded', this.object);
                EventBus.$emit('unselect-object', this.object);
                EventBus.$emit(
                    'editing-message',
                    'Object discarded.',
                    'success'
                );
            } catch (e) {
                console.error(e);
                EventBus.$emit(
                    'editing-message',
                    'Unable to discard object',
                    'error'
                );
            }
        },
        async clickUndiscard() {
            try {
                await api.deleteApi(`object/${this.object.id}`);
                EventBus.$emit('object-undiscarded', this.object);
                EventBus.$emit(
                    'editing-message',
                    'Object un-discarded.',
                    'success'
                );
                this.object.isDiscard = false;
            } catch (e) {
                console.error(e);
                EventBus.$emit(
                    'editing-message',
                    'Unable to un-discard object',
                    'error'
                );
            }
        },
        clickImage(id) {
            EventBus.$emit('image-show', id);
        },
    },
    watch: {
        async object() {
            if (!this.object || !this.object.images) {
                this.images = [];
            } else {
                const imageIds = this.object.images.data
                    .map((geojson) => geojson.id)
                    .slice(0, 5);
                const imageUrls = await Promise.all(
                    imageIds.map((id) => getImageUrls(id))
                );
                this.images = imageUrls;
            }
        },
    },
};
</script>

<style scoped></style>
