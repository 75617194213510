<template lang="pug">
#FlagFilter.text-center
    v-alert(:value="mapZoom < 10" icon="mdi-alert-circle" type="warning" elevation="0" outlined color="warning") Zoom in to see flags
    div(style="max-height:350px;overflow-y:scroll")
        v-checkbox(v-for="(v, k) of filters" v-model="filters[k].value" :label="v.text" hide-details)
</template>

<script>
import { EventBus } from '@/EventBus';
import Vue from 'vue';
import { flagTypes } from '@/featureTypes';
export default {
    name: 'FlagFilter',
    props: ['mapZoom'],
    data: () => ({
        showing: false,
        objectTypes: [],
        filters: {},
    }),

    async created() {
        window.FlagFilter = this;
        this.filters = {};
        for (const key of Object.keys(flagTypes)) {
            const flagType = flagTypes[key];
            Vue.set(this.filters, key, {
                text: flagType.text,
                value: true,
            });
        }
    },
    computed: {
        activeFilters() {
            return Object.keys(this.filters).filter(
                (k) => this.filters[k].value
            );
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                EventBus.$emit('update-flag-filter', this.activeFilters);
            },
        },
    },
};
</script>

<style scoped></style>
