<template lang="pug">
v-container(fluid)
    #map.map
    #controls.text-left(v-show="showControls || !smallScreen")
        v-col.text-left
            Basemap(:basemaps="basemaps" :map="map")

    Imagery(:visible="showImagery")
    Objects(:visible="showObjects" :showHidden="showHiddenObjects")
    //- div(style="position: absolute; width:100%; height:100%; pointer-events:none;")
    //- v-speed-dial(v-model="speedDial" bottom left direction="top" transition="slide-y-transition" absolute)
    //-     template(v-slot:activator)
    //-         v-btn(v-model="speedDial" color="blue darken-2" dark fab)
    //-             v-icon(v-if="speedDial") mdi-close
    //-             v-icon(v-else) mdi-account-circle

    //-     v-btn(fab color="blue darken-2" dark small)
    //-         v-icon mdi-layers
    //-     v-btn(fab color="blue darken-2" dark small)
    //-         v-icon mdi-information
    //- v-speed-dial(value="true" bottom left direction="top" absolute)
    //-     //- v-toolbar(absolute bottom left extended transparent flat)
    //-     v-btn(v-show="smallScreen" fab color="blue darken-2" dark small @click.stop="showControls = !showControls")
    //-         v-icon mdi-layers
    //-     v-btn(v-show="!sidepanelOpen" fab color="blue darken-2" dark small @click.stop="clickShowsidepanel")
    //-         v-icon mdi-information
    //-     v-btn
    //-         v-icon hello
        //- v-toolbar(absolute bottom left extended transparent flat)
    .floating-buttons(style="position: absolute; bottom: 50px; left:20px;")
        v-row
            v-btn.mb-4(v-show="smallScreen" fab color="blue" dark small @click.stop="showControls = !showControls")
                v-icon mdi-layers
        v-row
            v-btn.mb-4(v-show="!sidepanelOpen" fab color="blue" dark small @click.stop="clickShowsidepanel")
                v-icon mdi-information
        //- v-row
        //-     v-btn(fab small color="blue")
        //-         v-icon mdi-layers
</template>

<script>
// import mapboxgl from 'mapbox-gl';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-dev';
import U from 'mapbox-gl-utils';
import { EventBus } from '../EventBus';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import Imagery from './map/Imagery.vue';
import Basemap from './map/Basemap.vue';
import Features from './map/Features';
import Objects from './map/Objects.vue';
import Flags from './map/Flags';
import FeatureFilter from './map/FeatureFilter.vue';
import FlagFilter from './map/FlagFilter.vue';
import ObjectFilter from './map/ObjectFilter.vue';
export default {
    components: {
        Imagery,
        Basemap,
        Objects,
        ObjectFilter,
        FeatureFilter,
        FlagFilter,
    },
    data: () => ({
        map: null,
        basemaps: [
            {
                text: 'Street map',
                // value: 'mapbox://styles/light-v9',
                value: 'mapbox/streets-v11',
                // value: 'styles/light.json',
            },
            {
                text: 'Satellite + Streets',
                value: 'mapbox/satellite-streets-v11',
            },
            {
                text: 'Satellite',
                value: 'mapbox/satellite-v9',
            },
        ],
        showControls: false,
        smallScreen: window.innerWidth <= 400,
        showImagery: true,
        showObjects: true,
        showHiddenObjects: false,
        drawing: null, // what are we drawing right now, feature/flag/null
        speedDial: window.innerWidth <= 400,
        sidepanelOpen: window.App.drawer,
        alwaysTrue: true,
    }),
    async mounted() {
        mapboxgl.accessToken =
            window.location.hostname === 'mapswell.site44.com'
                ? 'pk.eyJ1Ijoic3RldmFnZSIsImEiOiJja25keW5pYTkxZGx3Mm5vb2UxdzE4cno2In0.ZjjS8eUNwE_vsBzg7hML8w'
                : 'pk.eyJ1IjoiYWlkZW5yb2hkZSIsImEiOiJja2tzZ2JnY3cwNnJ0MnZtcGdhNXhqanZjIn0.JJ1CZxjQKFC1Hfidt3KNgg';
        if (this.map) {
            return;
        }
        // We can end up with multiple maps due to switch to/from About page. Yech.
        if (window.map && window.map.queryRenderedFeatures) {
            window.map.remove();
            window.map = null;
        }
        this.initMap();

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl,
            countries: 'US',
        });
        window.g = geocoder;
        this.map.addControl(geocoder, 'top-left');
        window.map = this.map;

        window.app.Map = this;
        window.addEventListener(
            'resize',
            () => (this.smallScreen = window.innerWidth <= 400)
        );
        EventBus.$on(
            'toggle-sidepanel',
            (state) => (this.sidepanelOpen = state)
        );
    },

    methods: {
        mapC() {
            return window.map;
        },
        initMap() {
            this.map = new mapboxgl.Map({
                container: 'map',
                // center: [-95, 42],
                // zoom: 4,
                center: [-72.53, 44.21],
                zoom: 13,

                // style: this.basemaps[0].value,
                hash: 'center',
            });
            this.$nextTick(() => EventBus.$emit('load-basemap'));
            this.map.addControl(new mapboxgl.NavigationControl());
            U.init(this.map, mapboxgl);
            const Draw = new MapboxDraw({
                displayControlsDefault: false,
            });
            this.Draw = Draw;
            this.map.U.onLoad(async () => {
                console.log('map-loaded');
                EventBus.$emit('map-loaded', this.map);
                Features.init(this.map, this.Draw);
                Flags.init(this.map, this.Draw);
                this.$nextTick(() => {
                    EventBus.$emit('map-layers-added', this.map);
                    EventBus.$emit('map-zoom', this.map.getZoom());
                });
            });
            this.map.addControl(Draw, 'top-left');
            // This is a basic popup we use for providing information on mouseover
            this.map.popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
            });
            this.map.on('draw.create', (e) => {
                EventBus.$emit('drawing-end');
                this.drawCreate(e);
            });
            this.map.on('zoom', () => {
                EventBus.$emit('map-zoom', this.map.getZoom());
            });
            this.addHandlers();
            this.addOnSourceLoad();
        },
        addHandlers() {
            EventBus.$on('draw-feature', ({ geometryType }) => {
                const mode = {
                    Point: 'draw_point',
                    LineString: 'draw_line_string',
                }[geometryType];
                this.drawing = 'feature';
                this.Draw.deleteAll();
                this.Draw.changeMode(mode);
                EventBus.$emit('drawing-start');
            });
            EventBus.$on('draw-flag', () => {
                this.drawing = 'flag';
                this.Draw.deleteAll();
                this.Draw.changeMode('draw_point');
                EventBus.$emit('drawing-start');
            });
            EventBus.$on(['cancel-edit-feature', 'cancel-edit-flag'], () => {
                this.drawing = null;
                this.Draw.deleteAll();
                this.Draw.changeMode('simple_select');
                EventBus.$emit('drawing-end');
            });
            EventBus.$on('map-layers-added', () => {
                const clickableLayers = this.map
                    .getStyle()
                    .layers.filter((l) =>
                        l.id.match(/^(features|flags|objects)/)
                    )
                    .map((l) => l.id)
                    .reverse();

                this.map.U.clickOneLayer(
                    clickableLayers,
                    (e) => {
                        if (this.drawing) {
                            return;
                        }
                        console.log(e.features[0]);
                        if (e.layer.match(/^features/)) {
                            EventBus.$emit(
                                'select-feature',
                                e.features[0].properties
                            );
                        } else if (e.layer.match(/^flags/)) {
                            EventBus.$emit(
                                'select-flag',
                                e.features[0].properties
                            );
                        } else if (e.layer.match(/^objects/)) {
                            const obj = e.features[0];
                            // API changed type? our backend is strings, Mapillary is ints.
                            obj.properties.id = String(obj.properties.id);
                            obj.id = obj.properties.id;
                            obj.properties.isDiscard = window.Objects.isKeyDiscard(
                                obj.properties.id
                            );
                            EventBus.$emit('select-object', obj);
                        }

                        // this.unselectFlag();
                    },
                    () => {
                        if (!this.drawing) {
                            EventBus.$emit('unselect-feature');
                            EventBus.$emit('unselect-flag');
                            EventBus.$emit('unselect-object');
                        }
                    }
                );
            });
            EventBus.$on(
                'update-showImagery',
                (showImagery) => (this.showImagery = showImagery)
            );
            EventBus.$on(
                'update-showObjects',
                (showObjects) => (this.showObjects = showObjects)
            );
            EventBus.$on(
                'update-showHiddenObjects',
                (showHiddenObjects) =>
                    (this.showHiddenObjects = showHiddenObjects)
            );
        },
        async drawCreate(e) {
            const geometry = e.features[0].geometry;
            if (this.drawing === 'feature') {
                EventBus.$emit('feature-geometry', geometry);
            } else if (this.drawing === 'flag') {
                EventBus.$emit('flag-geometry', geometry);
            }
            this.drawing = null;
        },
        addOnSourceLoad() {
            // via markusand https://github.com/stevage/mapbox-gl-utils/issues/18#issuecomment-814131706
            this.map.U.onSourceLoad = (name, { onLoadStart, onLoadEnd }) => {
                // TODO incorporate into mapbox-gl-utils
                let isSourceLoading = false;
                this.map.on('sourcedata', (event) => {
                    if (event.sourceId !== name) return;

                    // Source starts loading
                    // Consider an "initial" data event when sourceDataType is undefined or `content`
                    // Discard `visibility` as it triggers very irregularly
                    const { sourceDataType = 'content' } = event;
                    if (!isSourceLoading && sourceDataType === 'content') {
                        if (onLoadStart) {
                            onLoadStart(this.map.getSource(name));
                        }
                        isSourceLoading = true;
                    }

                    // Source finishes loading
                    if (isSourceLoading && this.map.isSourceLoaded(name)) {
                        if (onLoadEnd) {
                            onLoadEnd(this.map.getSource(name));
                        }
                        isSourceLoading = false;
                    }
                });
            };
        },
        clickShowsidepanel() {
            EventBus.$emit('show-sidepanel');
        },
    },
};
</script>

<style>
.map {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
}

#overlay {
    pointer-events: none;
    /* background: blue; */
}

#controls {
    position: absolute;
    width: 180px;
    margin-top: 80px;
    background: white;
    margin-left: 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px 2px hsla(0, 0%, 0%, 0.1);
}
.small label {
    font-size: 12px;
}
</style>
