<template lang="pug">
#Objects
</template>

<script>
import { EventBus } from '@/EventBus';
import config from '@/config';

const ignoreTypes = `construction--barrier--temporary,construction--flat--driveway,object--banner,object--bench,object--bike-rack,object--catch-basin,object--fire-hydrant,object--junction-box,object--mailbox,object--manhole,object--parking-meter,object--phone-booth,object--sign--advertisement,object--sign--information,object--sign--store,object--street-light,object--support--pole,object--support--traffic-sign-frame,object--support--utility-pole,object--traffic-cone,object--trash-can,object--water-valve,construction--barrier--curb,construction--flat--bike-lane,construction--flat--curb-cut,construction--flat--parking,construction--flat--rail-track,construction--flat--road,construction--flat--service-lane,construction--flat--sidewalk,construction--flat--traffic-island,nature--snow,nature--vegetation,nature--water`.split(
    ','
);
import * as api from '@/api';
import { mapillaryTilesUrl } from '@/mapillary';
export default {
    name: 'Objects',
    data: () => ({
        discardKeys: new Set(),
        ignoreTypesFilter: [
            '!',
            ['in', ['get', 'value'], ['literal', ignoreTypes]],
        ],
        userFilter: {
            point: true,
            traffic_sign: true,
        },
        discardKeysFilter: true,
    }),
    props: ['visible', 'showHidden'],
    created() {
        window.Objects = this;
        EventBus.$on('map-loaded', (map) => this.init(map));
    },
    methods: {
        init(map) {
            this.map = map;
            this.minZoom = 14;
            map.U.addVector('objects-point', {
                tiles: [mapillaryTilesUrl('mly_map_feature_point')],
                minzoom: 14,
                maxzoom: 14,
            });
            map.U.addVector('objects-traffic_sign', {
                tiles: [mapillaryTilesUrl('mly_map_feature_traffic_sign')],
                minzoom: 14,
                maxzoom: 14,
            });
            map.U.addCircle('objects-point-highlight', 'objects-point', {
                sourceLayer: 'point',
                circleColor: 'hsla(20,100%,70%,1)',
                circleRadius: 25,
                circleBlur: 0.25,

                filter: false,
            });
            map.U.addCircle(
                'objects-traffic_sign-highlight',
                'objects-traffic_sign',
                {
                    sourceLayer: 'point',
                    circleColor: 'hsla(20,100%,70%,1)',
                    circleRadius: 25,
                    circleBlur: 0.25,

                    filter: false,
                }
            );

            map.U.addSymbol('objects-point-symbol', 'objects-point', {
                sourceLayer: 'point',
                iconImage: ['get', 'value'],
                iconAllowOverlap: true,
                iconSize: [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    12,
                    0.5,
                    15,
                    1,
                    18,
                    1.25,
                ],
            });

            map.U.addSymbol(
                'objects-traffic_sign-symbol',
                'objects-traffic_sign',
                {
                    sourceLayer: 'traffic_sign',
                    iconImage: ['get', 'value'],
                    iconAllowOverlap: true,
                    iconSize: [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        12,
                        0.5,
                        15,
                        1,
                        18,
                        1.5,
                    ],
                }
            );
            this.updateObjectFilters();

            map.U.hoverPointer([
                'objects-point-symbol',
                'objects-traffic_sign-symbol',
            ]);
            this.initDiscards();
            this.updateStyle();
            map.U.onSourceLoad('discards', {
                onLoadEnd: () => {
                    this.updateDiscardFilter();
                },
            });
            EventBus.$on('select-object', (object) => {
                const keyFilter = ['==', ['get', 'id'], +object.properties.id];
                const pointFilter = ['==', ['geometry-type'], 'Point'];

                map.U.setFilter(
                    [
                        'objects-point-highlight',
                        'objects-traffic_sign-highlight',
                    ],
                    ['all', keyFilter, pointFilter]
                );
            });
            EventBus.$on('unselect-object', () => {
                this.unselectObject();
            });
            EventBus.$on('object-discarded', (object) => {
                this.discardKeys.add(object.id);
                this.updateDiscardFilter(true);
                this.unselectObject();
                this.refreshDiscardTiles();
            });
            EventBus.$on('object-imported', (object) => {
                this.discardKeys.add(object.id);
                this.updateDiscardFilter(true);
                this.unselectObject();
                this.refreshDiscardTiles();
            });
            EventBus.$on('object-undiscarded', (object) => {
                this.discardKeys.delete(object.id);
                this.updateDiscardFilter(true);
                this.refreshDiscardTiles();
            });
            EventBus.$on('update-object-filter', (activeFilters) => {
                const branches = (layer) =>
                    activeFilters
                        .filter((f) => f.layer === layer)
                        .map(({ valuePrefix }) => {
                            return [
                                '==',
                                0,
                                ['index-of', valuePrefix, ['get', 'value']],
                            ];
                        });
                this.userFilter = {
                    point: ['any', ...branches('point')],
                    traffic_sign: ['any', ...branches('traffic_sign')],
                };

                this.updateObjectFilters();
            });
        },
        updateObjectFilters() {
            this.map.U.setFilter(
                ['objects-point-symbol'],
                this.objectsFilter('point')
            );
            this.map.U.setFilter(
                ['objects-traffic_sign-symbol'],
                this.objectsFilter('traffic_sign')
            );
        },
        unselectObject() {
            this.map.U.setFilter(
                ['objects-point-highlight', 'objects-traffic_sign-highlight'],
                false
            );
        },
        updateStyle() {
            this.map.U.toggle(
                [
                    'objects-point-symbol',
                    'objects-traffic_sign-symbol',
                    'objects-point-highlight',
                    'objects-traffic_sign-highlight',
                    'discards-circle',
                ],
                this.visible
            );
        },
        initDiscards() {
            this.discardKeys = new Set();
            this.map.U.addVector('discards', {
                tiles: [
                    api.apiUrl('tile/object/{z}/{x}/{y}.pbf?status=discard'),
                ],
                minzoom: 4,
            });
            // a handy debug layer sometimes
            this.map.U.addCircle('discards-circle', 'discards', {
                circleColor: 'hsla(330,100%,40%,0.5)',
                circleRadius: 18,
                circleOpacity: 0,
                sourceLayer: 'default',
            });
        },
        updateDiscardKeys() {
            // keep track of objects which are discarded or have been imported
            this.map
                .querySourceFeatures('discards', {
                    sourceLayer: 'default',
                })
                .map((f) => f.properties.mapillaryObjectKey)
                .forEach((key) => this.discardKeys.add(key));
        },
        updateDiscardFilter(skipQuery) {
            if (!skipQuery) {
                this.updateDiscardKeys();
            }
            const discardKeysExpr = [
                'in',
                ['get', 'id'],
                ['literal', [...[...this.discardKeys].map((k) => +k)]],
            ];
            this.discardKeysFilter = this.showHidden
                ? true
                : ['!', discardKeysExpr];
            this.updateObjectFilters();

            this.map.U.setIconOpacity(
                ['objects-point-symbol', 'objects-traffic_sign-symbol'],
                this.showHidden ? ['case', discardKeysExpr, 0.3, 1] : 1
            );
        },
        refreshDiscardTiles() {
            this.map.style.sourceCaches.discards.clearTiles();
            this.map.style.sourceCaches.discards.update(this.map.transform);
        },
        isKeyDiscard(key) {
            return this.discardKeys.has(key);
        },
        objectsFilter(layer) {
            return [
                'all',
                this.ignoreTypesFilter,
                this.userFilter[layer],
                this.discardKeysFilter,
            ];
        },
    },
    watch: {
        visible() {
            this.updateStyle();
        },
        showHidden() {
            this.updateDiscardFilter();
        },
    },
};
</script>

<style scoped></style>
