<template lang="pug">
#EditFeature(v-if="feature")
    h3(v-if="saved") Editing feature
    h3(v-if="!saved") New feature
    v-select.mt-5( v-model="feature.featureType" :items="featureTypeItems" label="Type of feature" ref="featureType" :menuProps="{ maxHeight: 500 }" dense)
        template(slot="item" slot-scope="data")
            .geometry-icon( :class="{ point: data.item.geometryType === 'Point'}")
                img(:src="data.item.geometryType === 'Point' ? 'noun_Pin_996804.png' : 'noun_Line Graph_1219836.png'")
            span {{ data.item.text }}
    template(v-if="feature.featureType")
        v-alert(v-if="checkGeometryWarning" dense outlined type="warning") This geometry was imported from a Mapillary object. Its location on the map is where the photo was taken from, not the object itself. Please check it carefully.
        v-btn(@click="startDrawing") {{ feature.geometry ? 'Redraw' : 'Draw' }} {{ geometryType === 'Point' ? 'point' : 'line' }} on map
    template(v-if="feature.geometry")
        v-select.mt-5(v-if="feature.featureType && subtypeItems.length" v-model="feature.subtype" :items="subtypeItems" label="Sub-type" clearable  :menuProps="{ maxHeight: 500 }" dense)
        v-text-field(v-if="feature.featureType==='Other'" v-model="feature.featureName" label="Feature name")
        v-text-field(v-if="feature.featureType==='Other'" v-model="feature.featureDescription" label="Feature description")
        v-combobox(v-show="feature.subtype" v-model="feature.device" :items="deviceItems" label="Device" clearable  dense ref="device")
        v-select(v-model="feature.roadType" :items="roadTypeItems" label="Road type" clearable  dense)
        template(v-if="feature.roadType")
            v-text-field(v-if="['InterstateOrMotorway', 'HighwayOrTrunk'].includes(feature.roadType)" v-model="feature.roadNumber" label="Road number")
            v-select.mt-5(v-if="['InterstateOrMotorway', 'HighwayOrTrunk'].includes(feature.roadType)" v-model="feature.direction" :items="directionItems" label="Direction"  dense)
            v-text-field(v-if="feature.roadType === 'Street'" v-model="feature.streetName" label="Street name")
        v-textarea(v-model="feature.source" label="Source" outlined height="4em")
        //- v-text-field(v-model="feature.wikiUrl" label="WikiURL")
        v-textarea(v-model="feature.notes" label="Notes" outlined)
        v-text-field(v-if="feature.mapillaryImageKey" v-model="feature.mapillaryImageKey" readonly label="Imagery Id")
        v-btn(v-if="canSave" @click="save" color="primary") Save
    v-btn(@click="cancel") Cancel
    v-alert(v-if="successMessage" dense text type="success") {{ successMessage }}

</template>

<script>
import { EventBus } from '@/EventBus';
import * as api from '@/api';
import { deviceList } from '@/devices';
import { convertMapillaryFeature } from './mapillaryConversion';
const itemList = (items) => items.map((id) => ({ text: id, value: id }));

import { featureTypes } from '@/featureTypes';

const featureTemplate = {
    id: null,
    geometry: null,
    featureType: null,
    subtype: null,
    roadType: null,
    featureName: null,
    featureDescription: null,
    direction: null,
    source: null,
    streetName: null,
    wikiUrl: null,
    notes: null,
    mapillaryImageKey: null,
};
export default {
    name: 'EditFeature',
    data: () => ({
        feature: null,
        successMessage: null,
        devices: [],
        checkGeometryWarning: false,
        mapillaryFeature: null,
    }),
    async created() {
        window.EditFeature = this;
        EventBus.$on('feature-geometry', (geometry) => {
            this.feature.geometry = geometry;
            this.checkGeometryWarning = false;
        });
        EventBus.$on('start-feature', ({ mapillaryFeature } = {}) => {
            this.feature = Object.assign({}, featureTemplate);
            // assign the current image to the feature
            this.feature.mapillaryImageKey = window.Imagery.getSelectedImageKey();
            this.mapillaryFeature = mapillaryFeature;
            if (mapillaryFeature) {
                convertMapillaryFeature(this.feature, mapillaryFeature);
                // BUG: for some reason, the 'device' field isn't populating at all. Something weird about
                // trying to assign it manually rather than via input doesn't work.
                this.checkGeometryWarning = true;
            }
            this.successMessage = null;
            this.$nextTick(() => {
                // would be nice to automatically pop open.
                // this.$refs.featureType.isMenuActive = true;
            });
        });
        EventBus.$on('edit-feature', (feature) => {
            this.successMessage = null;
            this.feature = Object.assign(
                {},
                featureTemplate,
                JSON.parse(JSON.stringify(feature))
            );
            // assign the current image to the feature
            this.feature.mapillaryImageKey = window.Imagery.getSelectedImageKey();
        });
        this.devices = await deviceList();
    },
    computed: {
        featureTypeItems() {
            return Object.values(featureTypes);
        },
        subtypeItems() {
            return (featureTypes[this.feature.featureType].subtypes || []).map(
                (id) => ({
                    text: id,
                    value: id,
                })
            );
        },
        roadTypeItems() {
            return [
                'InterstateOrMotorway',
                'HighwayOrTrunk',
                'Street',
            ].map((id) => ({ text: id, value: id }));
        },
        directionItems() {
            return itemList(
                'Northbound,Eastbound,Southbound,Westbound'.split(',')
            );
        },
        deviceItems() {
            return this.devices
                .filter(
                    (d) =>
                        d.Feature === this.feature.featureType &&
                        d.Subtype === this.feature.subtype
                )
                .map((d) => d.Device);
        },

        canSave() {
            let ret = this.feature.featureType && this.feature.geometry;
            if (this.feature.featureType === 'Other') {
                ret = this.featureName && this.featureDescription;
            }
            return ret;
        },
        saved() {
            return Number.isFinite(this.feature.id);
        },
        geometryType() {
            return featureTypes[this.feature.featureType].geometryType;
        },
        subtype() {
            return (this.feature || {}).subtype;
        },
        device() {
            return (this.feature || {}).device;
        },
    },
    watch: {
        feature: {
            deep: true,
            handler(current) {
                this.successMessage = null;
            },
        },
        subtype() {
            this.$nextTick(() => {
                // this.feature.device = '';
                if (this.$refs.device) {
                    this.$refs.device.setValue('');
                }
            });
        },
        device() {
            this.$nextTick(() => {
                const deviceDef = this.devices.find(
                    (d) =>
                        d.Feature === this.feature.featureType &&
                        d.Subtype === this.feature.subtype &&
                        d.Device === this.feature.device
                );
                this.feature.wikiUrl = deviceDef ? deviceDef.WikiLink : '';
            });
        },
    },
    methods: {
        startDrawing() {
            EventBus.$emit('draw-feature', {
                geometryType: this.geometryType,
                type: this.feature.featureType,
                subtype: this.feature.subtype,
            });
        },
        async save() {
            let result;
            if (this.saved) {
                result = await api.putApi('feature', this.feature);
                console.log(result);
                this.$nextTick(() => {
                    // this.successMessage = 'Updated feature.';
                    EventBus.$emit(
                        'editing-message',
                        'Updated feature.',
                        'success'
                    );
                    EventBus.$emit('load-feature', this.feature);
                    EventBus.$emit('feature-saved', this.feature);
                });
            } else {
                if (this.mapillaryFeature) {
                    const obj = {
                        mapillaryObjectKey: this.mapillaryFeature.properties
                            .key,
                        status: 'imported',
                        geometry: this.mapillaryFeature.geometry,
                    };
                    api.putApi(`object`, obj).then(() => {
                        EventBus.$emit(
                            'object-imported',
                            this.mapillaryFeature
                        );
                    });
                }
                result = await api.postApi('feature', this.feature);
                this.feature = result;
                // this.feature.id = result.id;
                this.$nextTick(() => {
                    // this.successMessage = 'Created feature.';
                    EventBus.$emit(
                        'editing-message',
                        'Created feature.',
                        'success'
                    );
                    EventBus.$emit('load-feature', this.feature);
                    EventBus.$emit('feature-saved', this.feature);
                }); // TODO properly check

                console.log(result);
                // this.saved = true;
            }
            // TODO: ensure the new feature is highlighted on map
            // TODO: ensure gl-draw gets wiped when we refresh
        },
        async cancel() {
            this.feature = null;
            EventBus.$emit('cancel-edit-feature');
        },
    },
};
</script>

<style scoped>
.geometry-icon {
    margin: 0 10px 0 0;
}
.geometry-icon.point {
    margin: 0 12px 0 3px;
}
.geometry-icon img {
    width: 15px;
}

.geometry-icon.point img {
    width: 10px;
}
</style>
