<template lang="pug">
#ObjectFilter.text-center
    v-checkbox(v-for="(v, k) of filters" v-model="filters[k].value" :label="v.text" hide-details)
</template>

<script>
import * as d3 from 'd3-fetch';
import { EventBus } from '@/EventBus';
import Vue from 'vue';
const mapillaryLayers = {
    Point: 'point',
    'Traffic Sign': 'traffic_sign',
};
export default {
    name: 'ObjectFilter',
    data: () => ({
        showing: false,
        objectTypes: [],
        filters: {},
    }),
    async created() {
        window.ObjectFilter = this;
        const objectTypes = (
            await d3.csv('mapillary_mapping_table.csv')
        ).filter((row) => row.FeatureType && row.MapillaryLayer !== 'Line');
        const layerTypes = [
            ...new Set(
                objectTypes.map(
                    (o) =>
                        `${o.MapillaryLayer}:${o.MapillaryType.split('--')[0]}`
                )
            ),
        ];
        this.filters = {};
        for (const layerType of layerTypes) {
            Vue.set(this.filters, layerType, {
                text: layerType,
                value: true,
            });
        }
        console.log(layerTypes);
    },
    computed: {
        activeFilters() {
            return Object.keys(this.filters)
                .filter((k) => this.filters[k].value)
                .map((k) => ({
                    layer: mapillaryLayers[k.split(':')[0]],
                    valuePrefix: k.split(':')[1],
                }));
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                EventBus.$emit('update-object-filter', this.activeFilters);
            },
        },
    },
};
</script>

<style scoped></style>
