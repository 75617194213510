<template lang="pug">
  v-main
    div(style="height: 100%; position:relative;")
      Map
  //-   div(style="clear:both")

</template>

<script>
import Map from '@/components/Map.vue';
import { EventBus } from '@/EventBus';
export default {
    name: 'Home',
    components: { Map },
    data: () => ({
        drawer: false, //true,
        sheet: true,
    }),
    methods: {},
};
// <template>
//     <div class="home">
//         <div v-if="!$auth.loading">
//             <button v-if="!$auth.isAuthenticated" @click="login">Log in</button>
//             <button v-if="$auth.isAuthenticated" @click="logout">
//                 Log out
//             </button>
//         </div>
//     </div>
// </template>
</script>

<style>
main .container {
    padding: 0;
}

#overlay {
    width: 100%;
}
</style>
